exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-category-test-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/blog/category-test/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-category-test-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-code-block-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/blog/code-block/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-code-block-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-get-started-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/blog/get-started/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-get-started-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-my-first-post-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/blog/my-first-post/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-my-first-post-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

